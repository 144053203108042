import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import parseQueryParameters from 'parse-url-query-params';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],

    providers: []
})
export class PaymentComponent implements OnInit {
    paymentUrl: string;
    paymentData: any;
    isMataraProUse = false;
    isCardcomTesting = false;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        public router: Router
    ) {
        this.route.queryParams.subscribe(params => {
            try {
                const paymentDataStr = params['paymentData'];
                this.paymentData = JSON.parse(decodeURIComponent(escape(atob(paymentDataStr))));

                if (this.isMataraProUse) {
                    this.paymentUrl = decodeURIComponent(escape(atob(params['paymentUrl']))); // 'https://www.matara.pro/nedarimplus/iframe';
                } else {
                    const successCallbackParameters = {
                        user_id: this.paymentData.avourlock,
                        amount: this.paymentData.Amount,
                        username: null
                    };


                    const myHeaders = new Headers();
                    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
                    const urlencoded = new URLSearchParams();

                    urlencoded.append('IsCreateInvoice', 'true');
                    urlencoded.append('InvoiceType', '301');
                    urlencoded.append('InvoiceHead.CustName', this.paymentData.ClientName);
                    urlencoded.append('InvoiceHead.Email', this.paymentData.Email);
                    urlencoded.append('InvoiceHead.SendByEmail', 'true');
                    urlencoded.append('InvoiceHead.Language', 'he');
                    urlencoded.append('InvoiceLines1.Description', this.paymentData.pricing.title);
                    urlencoded.append('InvoiceLines1.Price', this.paymentData.pricing.price);
                    urlencoded.append('SumToBill', this.paymentData.Amount);
                    urlencoded.append('InvoiceHead.CoinID', '1');
                    urlencoded.append('CoinID', '1');
                    urlencoded.append('InvoiceLines1.Quantity', this.paymentData.children.length);
                    if (!this.isCardcomTesting) {
                        urlencoded.append('TerminalNumber', '129844');
                        urlencoded.append('UserName', 'MKANELSKY');
                        successCallbackParameters.username = 'MKANELSKY';
                    } else {
                        urlencoded.append('TerminalNumber', '1000');
                        urlencoded.append('UserName', 'test9611');
                        successCallbackParameters.username = 'test9611';
                    }
                    urlencoded.append('APILevel', '10');
                    urlencoded.append('codepage', '65001');
                    urlencoded.append('Operation', '1');
                    urlencoded.append('Language', 'he');
                    urlencoded.append('ProductName', this.paymentData.pricing.title);
                    urlencoded.append('CancelType', '2');
                    urlencoded.append('MaxNumOfPayments', '6');
                    urlencoded.append('ShowInvoiceHead', 'true');
                    urlencoded.append('InvoiceHeadOperation', '1');
                    urlencoded.append('ReqCardOwnerPhone', 'true');
                    urlencoded.append('ShowCardOwnerPhone', 'true');
                    urlencoded.append('ShowCardOwnerEmail', 'true');
                    urlencoded.append('ReqCardOwnerEmail', 'true');
                    urlencoded.append('HideCardOwnerName', 'false');
                    urlencoded.append('CardOwnerName', this.paymentData.ClientName);
                    urlencoded.append('CardOwnerEmail', this.paymentData.Email);
                    urlencoded.append('CardOwnerPhone', this.paymentData.Phone);
                    urlencoded.append('InvoiceHead.CustCity', this.paymentData.City);
                    urlencoded.append('InvoiceHead.CustMobilePH', this.paymentData.Phone);
                    urlencoded.append('InvoiceHead.CustAddresLine1', this.paymentData.Street);
                    urlencoded.append('InvoiceHead.CompID', this.paymentData.Zeout);
                    urlencoded.append('InvoiceHead.Comments', this.paymentData.avour);
                    urlencoded.append('IndicatorUrl', `${window.location.origin}/api/1.0/payment/`);

                    const paymentDataQueryParams = Object.keys(successCallbackParameters).map(key => key + '=' + successCallbackParameters[key]).join('&');
                    urlencoded.append('SuccessRedirectUrl', `${window.location.origin}/payment-success-page/?${paymentDataQueryParams}`);
                    urlencoded.append('ErrorRedirectUrl', `${window.location.origin}/payment-error-page/`);

                    const requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                    } as RequestInit;

                    fetch('https://secure.cardcom.solutions/Interface/LowProfile.aspx', requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            const url = `${window.location.origin}?${result}`;
                            const parameters = parseQueryParameters(url);
                            this.paymentUrl = decodeURIComponent(parameters.url);
                        })
                        .catch(error => console.log('error', error));
                }
                /*
                const paymentIframe = document.getElementById('paymentIframe') as HTMLIFrameElement;
                if (paymentIframe === null) {
                    return;
                }
                paymentIframe.onload = () => {
                    const contentWindowPaymentIframe = paymentIframe.contentWindow;
                    contentWindowPaymentIframe.postMessage(this.paymentData, '*');
                };*/
            } catch (e) {
                console.error(e);
                this.location.back();
            }
        });
    }

    ngOnInit() {
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: Event) {
        this.handleMessage(event);
    }

    handleMessage(event: Event) {
        const message = event as MessageEvent;
        console.log(message.data);
        if (message.data && message.data.Name === 'TransactionResponse' && message.data.Value.Status !== 'Error') {
            console.log('Payment was successful');
        } else if (message.data && message.data._custom_type_ === 'CUSTOM_ON_URL_CHANGED') {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1000);
        }
    }
}
