import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import 'hammerjs';
import 'apple-mapkit-js';
import 'apple-mapkit-js/contains';
import {WOW} from 'wowjs/dist/wow.min';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

WOW.prototype.resetAnimation = function (event) {
    var target;
    if (event.type.toLowerCase().indexOf('animationend') >= 0) {
        target = event.target || event.srcElement;
        if (target instanceof SVGElement) {
            return target.setAttribute('class', target.getAttribute('class').replace(this.config.animateClass, '').trim());
        } else {
            return target.className = target.className.replace(this.config.animateClass, '').trim();
        }
    }
};

WOW.prototype.show = function (box) {
    this.applyStyle(box);
    if (box instanceof SVGElement) {
        box.setAttribute('class', box.getAttribute('class') + " " + this.config.animateClass);
    } else {
        box.className = box.className + " " + this.config.animateClass;
    }

    if (this.config.callback != null) {
        this.config.callback(box);
    }
    this.util().emitEvent(box, this.wowEvent);
    this.util().addEvent(box, 'animationend', this.resetAnimation);
    this.util().addEvent(box, 'oanimationend', this.resetAnimation);
    this.util().addEvent(box, 'webkitAnimationEnd', this.resetAnimation);
    this.util().addEvent(box, 'MSAnimationEnd', this.resetAnimation);
    return box;
};
