<div class="payment-page">
    <div class="main-image-wrapper"></div>

    <div class="content-form">
        <div class="container">
                <div class="row payment-form-row">
                    <iframe *ngIf="paymentUrl" class="payment-frame" id="paymentIframe" width="100%" height="100%" [src]="paymentUrl | safe"></iframe>
                </div>
        </div>
    </div>
</div>
