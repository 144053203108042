import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandlerService} from './http-error-handler.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Lesson} from '../interfaces/lesson';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class LessonService {
    private handleError: HandleError;
    lessonsUrl = 'api/1.0/student/lesson/';

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandlerService) {
        this.handleError = httpErrorHandler.createHandleError('LessonService');
    }

    getLessons(): Observable<Lesson[]> {
        return this.httpFetchLessons().pipe(
                catchError(this.handleError('getLessons', []))
            );
    }

    httpFetchLessons(): Observable<Lesson[]> {
        return this.http.get<Lesson[]>(this.lessonsUrl, httpOptions);
    }
}
