/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-error.component";
var styles_PaymentErrorComponent = [i0.styles];
var RenderType_PaymentErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentErrorComponent, data: {} });
export { RenderType_PaymentErrorComponent as RenderType_PaymentErrorComponent };
export function View_PaymentErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "payment-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "main-image-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "content-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05E2\u05E1\u05E7\u05D4 \u05E0\u05DB\u05E9\u05DC\u05D4. "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DC\u05E7\u05D5\u05D7 \u05E0\u05DB\u05D1\u05D3, \u05DC\u05D0 \u05E0\u05D9\u05EA\u05DF \u05DC\u05D7\u05D9\u05D9\u05D1 \u05DB\u05E8\u05D8\u05D9\u05E1 \u05D6\u05D4. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "btn btn-default"], ["href", "/"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05E2\u05D1\u05D5\u05E8 \u05DC\u05E2\u05DE\u05D5\u05D3 \u05D4\u05D1\u05D9\u05EA"]))], null, null); }
export function View_PaymentErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-error", [], null, null, null, View_PaymentErrorComponent_0, RenderType_PaymentErrorComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentErrorComponentNgFactory = i1.ɵccf("app-payment-error", i2.PaymentErrorComponent, View_PaymentErrorComponent_Host_0, {}, {}, []);
export { PaymentErrorComponentNgFactory as PaymentErrorComponentNgFactory };
