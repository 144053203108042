import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-payment-error',
    templateUrl: './payment-error.component.html',
    styleUrls: ['./payment-error.component.scss'],

    providers: []
})
export class PaymentErrorComponent implements OnInit {
    ngOnInit(): void {
    }
}
