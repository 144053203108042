/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-success.component";
var styles_PaymentSuccessComponent = [i0.styles];
var RenderType_PaymentSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentSuccessComponent, data: {} });
export { RenderType_PaymentSuccessComponent as RenderType_PaymentSuccessComponent };
export function View_PaymentSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "payment-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "main-image-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "content-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05E2\u05E1\u05E7\u05D4 \u05D1\u05D5\u05E6\u05E2\u05D4 \u05D1\u05D4\u05E6\u05DC\u05D7\u05D4. "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DC\u05E7\u05D5\u05D7 \u05E0\u05DB\u05D1\u05D3 , \u05D4\u05E2\u05E1\u05E7\u05D4 \u05D1\u05D5\u05E6\u05E2\u05D4 \u05D1\u05D4\u05E6\u05DC\u05D7\u05D4. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "btn btn-default"], ["href", "/"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05E2\u05D1\u05D5\u05E8 \u05DC\u05E2\u05DE\u05D5\u05D3 \u05D4\u05D1\u05D9\u05EA"]))], null, null); }
export function View_PaymentSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-success", [], null, null, null, View_PaymentSuccessComponent_0, RenderType_PaymentSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentSuccessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentSuccessComponentNgFactory = i1.ɵccf("app-payment-success", i2.PaymentSuccessComponent, View_PaymentSuccessComponent_Host_0, {}, {}, []);
export { PaymentSuccessComponentNgFactory as PaymentSuccessComponentNgFactory };
