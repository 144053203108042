/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-to-top.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./scroll-to-top.component";
var styles_ScrollToTopComponent = [i0.styles];
var RenderType_ScrollToTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollToTopComponent, data: {} });
export { RenderType_ScrollToTopComponent as RenderType_ScrollToTopComponent };
export function View_ScrollToTopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "scroll-to-top"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "show-scrollTop": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "icon-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["arrow_upward"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "scroll-to-top"; var currVal_1 = _ck(_v, 3, 0, _co.windowScrolled); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ScrollToTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scroll-to-top", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ScrollToTopComponent_0, RenderType_ScrollToTopComponent)), i1.ɵdid(1, 114688, null, 0, i3.ScrollToTopComponent, [i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollToTopComponentNgFactory = i1.ɵccf("app-scroll-to-top", i3.ScrollToTopComponent, View_ScrollToTopComponent_Host_0, {}, {}, []);
export { ScrollToTopComponentNgFactory as ScrollToTopComponentNgFactory };
