import {AfterViewInit, Component, OnInit} from '@angular/core';
import 'apple-mapkit-js';
import 'apple-mapkit-js/contains';
import {WOW} from 'wowjs/dist/wow.min';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../../interfaces/user';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {
    private formSubmitAttempt: boolean;

    mapLocation = {
        title: 'ועד תלמידי התמימים העולמי',
        subtitle: '784 Eastern Parkway Suite 400',
        lat: 40.6691452,
        lng: -73.9437052,
        zoom: 8
    };
    contactUsFormGroup: FormGroup;
    isDataSubmitting: boolean;
    generalError: boolean;
    isSuccessContactUs: boolean;

    CONTACT_US_URL = '/api/1.0/contact-us/';

    constructor(
        private formBuilder: FormBuilder,
        private httpClient: HttpClient
    ) {
    }

    ngAfterViewInit() {
        const wow = new WOW({
            live: false
        });
        wow.init();
    }

    ngOnInit() {
        this.isDataSubmitting = false;
        this.generalError = false;
        this.isSuccessContactUs = false;
        this.formSubmitAttempt = false;

        // @ts-ignore
        mapkit.init({
            authorizationCallback(done) {
                fetch('/api/1.0/jwt/')
                    .then(response => response.json())
                    .then(result => {
                        done(result.token);
                    });

            }
        });
        // @ts-ignore
        const MarkerAnnotation = mapkit.MarkerAnnotation;
        // @ts-ignore
        const organizationCoords = new mapkit.Coordinate(this.mapLocation.lat, this.mapLocation.lng);
        // @ts-ignore
        const sfoRegion = new mapkit.CoordinateRegion(
            // @ts-ignore
            new mapkit.Coordinate(this.mapLocation.lat, this.mapLocation.lng),
            // @ts-ignore
            new mapkit.CoordinateSpan(0.0125, 0.0125)
        );
        // @ts-ignore
        const map = new mapkit.Map('map',
            {
                // @ts-ignore
                showsCompass: mapkit.FeatureVisibility.Visible,
                showsZoomControl: true,
                showsMapTypeControl: true
            });

        const annotation = new MarkerAnnotation(organizationCoords, {
            color: '#d8742c',
            title: this.mapLocation.title,
            subtitle: this.mapLocation.subtitle,
            glyphText: '🕍️'
        });
        map.showItems([annotation]);
        map.region = sfoRegion;

        this.contactUsFormGroup = this.formBuilder.group({
            firstName: new FormControl('', [
                Validators.required
            ]),
            lastName: new FormControl('', [
                Validators.required
            ]),
            email: new FormControl('', [
                Validators.required, Validators.email
            ]),
            message: new FormControl('', [
                Validators.required
            ]),
        });
    }

    isFieldInvalid(formControl: AbstractControl) {
        return this.formSubmitAttempt && !!formControl.errors;
    }

    onSendMessageSubmit() {
        this.formSubmitAttempt = true;
        this.generalError = false;

        if (this.contactUsFormGroup.valid) {
            this.isDataSubmitting = true;
            const formValue = this.contactUsFormGroup.value;
            const data = {
                first_name: formValue.firstName,
                last_name: formValue.lastName,
                email: formValue.email,
                message: formValue.message
            };
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            this.httpClient.post<User>(this.CONTACT_US_URL, data, httpOptions).subscribe(
                (res) => {
                    this.isDataSubmitting = false;
                    this.formSubmitAttempt = false;
                    this.contactUsFormGroup.reset();
                    this.isSuccessContactUs = true;

                    setTimeout(() => {
                        this.isSuccessContactUs = false;
                    }, 5000);
                },
                (err) => {
                    this.isDataSubmitting = false;
                    this.generalError = true;
                    console.log(err);
                }
            );
        }
    }

    get firstName() {
        return this.contactUsFormGroup.get('firstName');
    }

    get lastName() {
        return this.contactUsFormGroup.get('lastName');
    }

    get email() {
        return this.contactUsFormGroup.get('email');
    }

    get message() {
        return this.contactUsFormGroup.get('message');
    }
}
