/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../classes/safe-pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./payment.component";
import * as i6 from "@angular/router";
var styles_PaymentComponent = [i0.styles];
var RenderType_PaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentComponent, data: {} });
export { RenderType_PaymentComponent as RenderType_PaymentComponent };
function View_PaymentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iframe", [["class", "payment-frame"], ["height", "100%"], ["id", "paymentIframe"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.paymentUrl)); _ck(_v, 0, 0, currVal_0); }); }
export function View_PaymentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "payment-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "main-image-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "content-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row payment-form-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentUrl; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_PaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMessage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PaymentComponent_0, RenderType_PaymentComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentComponent, [i6.ActivatedRoute, i4.Location, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentComponentNgFactory = i1.ɵccf("app-payment", i5.PaymentComponent, View_PaymentComponent_Host_0, {}, {}, []);
export { PaymentComponentNgFactory as PaymentComponentNgFactory };
