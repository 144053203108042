import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './components/landing/landing.component';
import {PagenotfoundComponent} from './components/pagenotfound/pagenotfound.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {PaymentComponent} from './components/payment/payment.component';
import {PaymentSuccessComponent} from './components/payment/payment-success.component';
import {PaymentErrorComponent} from './components/payment/payment-error.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'landing'},
    {path: 'landing', component: LandingComponent},
    {path: 'registration', component: RegistrationComponent},
    {path: 'payment', component: PaymentComponent},
    {path: 'payment-success', component: PaymentSuccessComponent},
    {path: 'payment-error', component: PaymentErrorComponent},
    {path: '**', component: PagenotfoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false, initialNavigation: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
