import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-payment-success',
    templateUrl: './payment-success.component.html',
    styleUrls: ['./payment-success.component.scss'],

    providers: []
})
export class PaymentSuccessComponent implements OnInit {
    ngOnInit(): void {
    }
}
