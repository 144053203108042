<div class="payment-page">
    <div class="main-image-wrapper"></div>

    <div class="content-form">
        <div class="container">
            <div class="title">
                עסקה בוצעה בהצלחה.
            </div>
            <div class="subtitle">
                לקוח נכבד , העסקה בוצעה בהצלחה.
            </div>

            <div>
                <a type="button" class="btn btn-default" href="/">עבור לעמוד הבית</a>
            </div>
        </div>
    </div>
</div>
