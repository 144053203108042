<div class="text-wrapper">
    <div class="title" data-content="404">
        404
    </div>

    <div class="subtitle">
        Oops, the page you're looking for doesn't exist.
    </div>

    <div>
        <a type="button" class="btn btn-default" href="/">Go to homepage</a>
    </div>
</div>
