<div class="landing-page">
    <div class="main-image-wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="content-text">
                    <h2>"איר לערנט דאָך אין דעם רבי'נס ישיבה, זייט איר</h2>
                    <h1>דאָך דעם רבי'נס קינדער"</h1>
                    <h6>(מדבריו של הרבי קודם "ברכת הבנים" בערב-יום-כיפור תשי"ב)</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="plans-description" id="activity" #activity>
        <p class="caption">'ועד תלמידי התמימים העולמי' מפעיל תכניות שונות, בהכוונת הנהלות הישיבות,
            בחודש תשרי ובמהלך השנה</p>
        <div class="flex-layout">
            <div class="col2 wow slideInDown fadeIn" data-wow-duration="1.6s">
                <div class="white-frame">
                    <div class="centered">תכנית תשרי</div>
                </div>
                <img class="annual-plan" src="../../../static/app/assets/img/2.png">
            </div>

            <div class="col1 wow slideInDown fadeIn" data-wow-duration="1.6s">
                <div class="white-frame">
                    <div class="centered">תכנית שנתית</div>
                </div>
                <img class="annual-plan" src="../../../static/app/assets/img/1.png">
            </div>
        </div>
    </div>

    <div *ngIf="isRegistrationEnabled" class="pricing" id="registration" #registration>
        <div class="layout">
            <div class="col-sm-12">
                <h3 class="flex-center">החיוב יתבצע בש"ח בהתאם לשער הדולר במועד פתיחת ההרשמה</h3>
            </div>
        </div>
        <div class="layout">
            <div class="col{{i + 1}} wow slideInUp fadeIn" (click)="openRegistration(pricing);" data-wow-duration="1.6s"
                 *ngFor="let pricing of pricingMapping; let i = index">
                <p class="text-pricing" [textContent]="pricing.title"></p>
                <hr/>
                <div class="layout" *ngIf="!pricing.is_paid_in_dollars || !pricing.price_in_dollars">
                    <div class="col-sm-6">
                        <p class="old-price"><s><span [textContent]="pricing.old_price | number"></span> ₪</s></p>
                    </div>
                    <div class="col-sm-6">
                        <p class="price"><span [textContent]="pricing.price | number"></span> ₪</p>
                    </div>
                </div>
                 <div class="layout" *ngIf="pricing.is_paid_in_dollars || pricing.price_in_dollars">
                    <div class="col-sm-12">
                        <p class="price">$<span [textContent]="pricing.price_in_dollars | number"></span></p>
                    </div>
                </div>
                <div class="layout">
                    <div class="col-sm-12">
                        <p *ngIf="pricing.content_subtitle" [textContent]="pricing.content_subtitle"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="plan-content-menu" id="gallery" #gallery>
        <img class="content-image" src="../../../static/app/assets/img/3.png">
        <div class="layout">
            <div class="col1 wow slideInUp fadeIn" data-wow-duration="1.6s">
                <img class="annual-plan" src="../../../static/app/assets/img/4.png">
                <p class="caption">הכנסת אורחים</p>
            </div>
            <div class="col2 wow slideInDown fadeIn" data-wow-duration="1.6s">
                <img class="annual-plan" src="../../../static/app/assets/img/5.png">
                <p class="caption">הוצאה לאור</p>
            </div>
            <div class="col3 wow slideInUp fadeIn" data-wow-duration="1.6s">
                <img class="annual-plan" src="../../../static/app/assets/img/6.png">
                <p class="caption">תכניות לימוד</p>
            </div>
            <div class="col4 wow slideInDown fadeIn" data-wow-duration="1.6s">
                <img class="annual-plan" src="../../../static/app/assets/img/7.png">
                <p class="caption">התוועדויות וכינוסים</p>
            </div>
        </div>
    </div>

    <div class="donate-content" id="about" #about>
        <div class="white-frame">
            <div class="row">
                <div class="col-md-12">
                    <div class="centered">
                        <div class="caption">
                            הֱיֵה שותף!
                        </div>
                        <div class="sub-caption">
                            תרום לועד תלמידי התמימים העולמי
                        </div>
                        <div class="content">
                            ועד תלמידי התמימים העולמי" מקיים פעילות ענפה לאורך כל השנה, ומערך ענק לאירוח אלפי תלמידי
                            התמימים
                            המסתופפים בחצרות קדשנו.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row button-wrapper">
                <div class="col-md-12">
                    <button class="btn-join">טול חלק והֱיֵה שותף עמנו »</button>
                </div>
            </div>
        </div>
        <img class="donate-content-image" src="../../../static/app/assets/img/8.png">
    </div>
</div>

<app-scroll-to-top></app-scroll-to-top>
