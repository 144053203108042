import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LessonService} from '../../services/lesson.service';
import {YeshivaService} from '../../services/yeshiva.service';
import {Lesson} from '../../interfaces/lesson';
import {Yeshiva} from '../../interfaces/yeshiva';
import {forkJoin} from 'rxjs';
import {User} from '../../interfaces/user';
import {WOW} from 'wowjs/dist/wow.min';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {PricingPlan} from '../../interfaces/pricing';
import {RegistrationTermsContentDialogComponent} from './registration-terms.component';
import {SiteConfigurationService} from '../../services/configuration.service';
import {SiteConfiguration} from '../../interfaces/configuration';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],

    providers: []
})
export class RegistrationComponent implements OnInit, AfterViewInit {
    private _currentStep: number;
    private formSubmitAttempt: boolean;
    siteConfiguration: SiteConfiguration;
    passedStep: number;

    isPaymentAtReceptionDesk: boolean;
    students: {
        id: number,
        firstName: string,
        lastName: string,
        birthDate: string,
        passportID: string,
        yeshiva: number,
        lesson: number
        covid19VaccinationCertificate: File,
        flightTicket: File
    }[];

    user: User = {
        lastName: null,
        children: [],
        shipment: {
            address: null,
            apartment: null,
            city: null,
            phone: null,
            zipCode: null,
            whatsAppPhone: null,
        },
        mother: {
            firstName: null,
            passportID: null,
            birthDate: null,
            phone: null,
            email: null
        },
        father: {
            firstName: null,
            passportID: null,
            birthDate: null,
            phone: null,
            email: null
        }
    };
    lessons: Lesson[];
    yeshivas: Yeshiva[];
    pricing: PricingPlan;
    isDataSubmitting: boolean;
    generalError: boolean;
    isSuccessRegistration: boolean;
    paymentUrl: string = `https://www.matara.pro/nedarimplus/online/?mosad=7001858` +
        `&NormalDefault=1` +
        `&avour=וועד%20התמימים%20העולמי%20בשיתוף%20חיוכו%20של%20ילד%20&` +
        `avourlock=1&` +
        `Analytic=1&` +
        `logo=9000802_0.jpg` +
        `&ClientName=David Baum&` +
        `Comments=1&` +
        `Comment=1&` +
        `Email=serjantik@gmail.com&` +
        `Phone=0919389893&Street=Balfour&` +
        `City=Bat Yam&` +
        `Zeout=321877714&AmountLock=1&Amount=11&CallBack=https://vaadhatmimim.com/api/1.0/user/1/payment/`;
    paymentData: any = {};
    SERVER_URL = '/api/1.0/student/';
    registrationForm: FormGroup;
    parentPhonesGroup: FormGroup;
    parentEmailsGroup: FormGroup;
    studentsArrayGroup: FormArray;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private sanitizer: DomSanitizer,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private lessonService: LessonService,
        private yeshivaService: YeshivaService,
        private siteConfigurationService: SiteConfigurationService,
        public registrationTermsAndConditionsDialog: MatDialog
    ) {
        this.route.queryParams.subscribe(params => {
            try {
                this.pricing = JSON.parse(decodeURIComponent(escape(atob(params['pricing']))));
            } catch (e) {
                this.router.navigate(['/']);
            }
        });
    }

    ngAfterViewInit() {
        const wow = new WOW({
            live: false
        });
        wow.init();
    }

    ngOnInit() {
        this.students = [];
        this._currentStep = 1;
        this.passedStep = 1;
        this.isPaymentAtReceptionDesk = false;
        this.formSubmitAttempt = false;
        this.isDataSubmitting = false;
        this.generalError = false;
        this.isSuccessRegistration = false;

        this.parentPhonesGroup = this.formBuilder.group({
            motherPhone: new FormControl(this.user.mother.phone, []),
            fatherPhone: new FormControl(this.user.father.phone, [])
        }, {
            validator: this.validateParentPhones.bind(this)
        });

        this.parentEmailsGroup = this.formBuilder.group({
            fatherEmail: new FormControl(this.user.father.email, []),
            motherEmail: new FormControl(this.user.mother.email, []),
        }, {
            validator: this.validateParentEmails.bind(this)
        });
        this.studentsArrayGroup = this.formBuilder.array([
            this.addStudentFormGroup()
        ]);

        this.registrationForm = new FormGroup({
            fatherName: new FormControl(this.user.father.firstName, [
                Validators.required
            ]),
            familyName: new FormControl(this.user.lastName, [
                Validators.required
            ]),
            fatherID: new FormControl(this.user.father.passportID, [
                Validators.required
            ]),
            motherName: new FormControl(this.user.mother.firstName, [
                Validators.required
            ]),
            motherID: new FormControl(this.user.mother.passportID, [
                Validators.required
            ]),
            motherBirthDate: new FormControl(this.user.mother.birthDate, [
                Validators.required
            ]),
            fatherBirthDate: new FormControl(this.user.mother.birthDate, [
                Validators.required
            ]),
            parentPhonesGroup: this.parentPhonesGroup,
            parentEmailsGroup: this.parentEmailsGroup,
            address: new FormControl(this.user.shipment.address, [Validators.required]),
            whatsAppPhone: new FormControl(this.user.shipment.whatsAppPhone, []),
            apartment: new FormControl(this.user.shipment.apartment, [Validators.required]),
            city: new FormControl(this.user.shipment.city, [Validators.required]),
            phone: new FormControl(this.user.shipment.phone, [Validators.required]),
            zipCode: new FormControl(this.user.shipment.zipCode, [Validators.required]),
            studentsArrayGroup: this.studentsArrayGroup
        });

        forkJoin([
            this.yeshivaService.httpFetchYeshivas(),
            this.lessonService.httpFetchLessons(),
            this.siteConfigurationService.httpSiteConfiguration()
        ]).subscribe(results => {
            this.yeshivas = results[0];
            this.lessons = results[1];
            this.siteConfiguration = results[2];
        });
    }

    validateParentPhones(group: FormGroup) {
        if (group.get('fatherPhone').value || group.get('motherPhone').value) {
            group.get('fatherPhone').setErrors(null);
        } else if (this.formSubmitAttempt) {
            group.get('fatherPhone').setErrors({parentPhones: true});
        }
        return null;
    }

    validateParentEmails(group: FormGroup) {
        if (group.get('fatherEmail').value || group.get('motherEmail').value) {
            group.get('fatherEmail').setErrors(null);
            const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (group.get('motherEmail').value && !emailRegexp.test(group.get('motherEmail').value)) {
                group.get('motherEmail').setErrors({motherEmail: true});
            }
            if (group.get('fatherEmail').value && !emailRegexp.test(group.get('fatherEmail').value)) {
                group.get('fatherEmail').setErrors({fatherEmail: true});
            }
        } else if (this.formSubmitAttempt) {
            group.get('fatherEmail').setErrors({parentEmails: true});
        }
        return null;
    }

    isFieldInvalid(formControl: AbstractControl) {
        if (this.formSubmitAttempt && !!formControl.errors) {
            this.passedStep = this.currentStep;
        }
        return this.formSubmitAttempt && !!formControl.errors;
    }

    get isFormValid(): boolean {
        if (this.currentStep === 1) {
            return !this.familyName.invalid &&
                !this.fatherBirthDate.invalid &&
                !this.motherBirthDate.invalid &&
                !this.fatherID.invalid &&
                !this.fatherName.invalid &&
                !this.motherID.invalid &&
                !this.motherName.invalid;
        } else if (this.currentStep === 2) {
            return !this.phone.invalid &&
                !this.city.invalid &&
                !this.zipCode.invalid &&
                !this.address.invalid &&
                !this.apartment.invalid &&
                this.parentEmailsGroup.valid &&
                this.parentPhonesGroup.valid;
        }
        return this.registrationForm.valid;
    }

    @Input()
    set currentStep(value: number) {
        if (value) {
            this._currentStep = value;
        }
    }

    get currentStep(): number {
        return this._currentStep;
    }

    onStepClick(step) {
        if (step > this.currentStep && this.passedStep < step) {
            return;
        } else {
            this.currentStep = step;
        }
    }

    getTimestamp(date: Date): number {
        const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
        const convertedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        return new Date(convertedDate.getTime() - userTimezoneOffset).getTime() / 1000;
    }

    parseFormData() {
        const formValue = this.registrationForm.value;
        this.user = {
            lastName: formValue.familyName,
            children: [],
            shipment: {
                address: formValue.address,
                apartment: formValue.apartment,
                city: formValue.city,
                phone: formValue.phone,
                zipCode: formValue.zipCode,
                whatsAppPhone: formValue.whatsAppPhone
            },
            mother: {
                firstName: formValue.motherName,
                passportID: formValue.motherID,
                birthDate: formValue.motherBirthDate ? this.getTimestamp(formValue.motherBirthDate) : null,
                phone: formValue.parentPhonesGroup.motherPhone,
                email: formValue.parentEmailsGroup.motherEmail
            },
            father: {
                firstName: formValue.fatherName,
                passportID: formValue.fatherID,
                birthDate: formValue.fatherBirthDate ? this.getTimestamp(formValue.fatherBirthDate) : null,
                phone: formValue.parentPhonesGroup.fatherPhone,
                email: formValue.parentEmailsGroup.fatherEmail
            }
        };

        for (const studentData of formValue.studentsArrayGroup) {
            const student = {
                birthDate: studentData.birthDate ? this.getTimestamp(studentData.birthDate) : null,
                firstName: studentData.firstName,
                lastName: studentData.lastName,
                lesson: studentData.lesson,
                passportID: studentData.passportID,
                yeshiva: studentData.yeshiva,
                arrivalDate: studentData.arrivalDate ? this.getTimestamp(studentData.arrivalDate) : null,
                leaveDate: studentData.leaveDate ? this.getTimestamp(studentData.leaveDate) : null,
                medicalRemarks: studentData.medicalRemarks,
                isLodging: !!studentData.isLodging,
                isFood: !!studentData.isFood,
                hasMedicalInsurance: !!studentData.hasMedicalInsurance,
                covid19VaccinationCertificate: studentData.covid19VaccinationCertificate,
                flightTicket: studentData.flightTicket,
                isPaymentAtReceptionDesk: this.isPaymentAtReceptionDesk
            };
            this.user.children.push(student);
        }
    }

    onRegisterSubmit() {
        this.parseFormData();
        this.formSubmitAttempt = true;
        this.generalError = false;
        if (this.currentStep === 2) {
            this.parentPhonesGroup.updateValueAndValidity();
            this.parentEmailsGroup.updateValueAndValidity();
        }

        if (this.isFormValid) {
            if (this.currentStep < 3) {
                this.currentStep++;
                this.passedStep = this.currentStep;
                this.formSubmitAttempt = false;
            } else {
                this.openTermsAndConditionsDialog();
            }
        }
    }

    onPayClick() {
        // window.open(this.paymentUrl, '_blank');
        const navigationExtras: NavigationExtras = {
            queryParams: {
                paymentUrl: btoa(unescape(encodeURIComponent(this.paymentUrl))),
                paymentData: btoa(unescape(encodeURIComponent(JSON.stringify(this.paymentData))))
            }
        };
        this.router.navigate(['/payment'], navigationExtras);
    }

    addStudentFormGroup(): FormGroup {
        const controlsGroup = {
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            birthDate: new FormControl('', [Validators.required]),
            passportID: new FormControl('', [Validators.required]),
            yeshiva: new FormControl('', [Validators.required]),
            lesson: new FormControl('', [Validators.required]),
            flightTicket: new FormControl('', [Validators.required])
            // covid19VaccinationCertificate: new FormControl('', [Validators.required])
        };

        if (this.pricing.is_show_amenities) {
            controlsGroup['arrivalDate'] = new FormControl('', [Validators.required]);
            controlsGroup['leaveDate'] = new FormControl('', [Validators.required]);
            controlsGroup['medicalRemarks'] = new FormControl('', []);
            controlsGroup['isLodging'] = new FormControl('', []);
            controlsGroup['isFood'] = new FormControl('', []);
            // controlsGroup['hasMedicalInsurance'] = new FormControl('', []);
        }
        const formGroup: FormGroup = this.formBuilder.group(controlsGroup);

        if (this.yeshivas && this.yeshivas.length > 0) {
            formGroup.controls['yeshiva'].setValue(this.yeshivas[0].id, {onlySelf: true});
        }

        if (this.lessons && this.lessons.length > 0) {
            formGroup.controls['lesson'].setValue(this.lessons[0].id, {onlySelf: true});
        }

        return formGroup;
    }

    addStudent() {
        let isValidStudentsForm = true;

        for (const formGroup of this.studentsArrayGroup.controls) {
            const group = formGroup as FormGroup;
            group.markAllAsTouched();
            group.updateValueAndValidity();

            Object.keys(group.controls).forEach((key: string) => {
                const abstractControl = group.get(key);
                abstractControl.markAsTouched({onlySelf: true});
                abstractControl.updateValueAndValidity();
            });
            if (!group.valid) {
                this.formSubmitAttempt = true;
                this.registrationForm.markAsTouched({onlySelf: true});
                this.registrationForm.updateValueAndValidity();
                isValidStudentsForm = false;
            } else {
                this.formSubmitAttempt = false;
            }
        }
        if (isValidStudentsForm) {
            this.studentsArrayGroup.push(this.addStudentFormGroup());
        }
    }

    removeStudent(index) {
        this.studentsArrayGroup.removeAt(index);
    }

    openTermsAndConditionsDialog() {
        const dialogRef = this.registrationTermsAndConditionsDialog.open(RegistrationTermsContentDialogComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result.isPaymentAtReceptionDesk) {
                this.isPaymentAtReceptionDesk = result.isPaymentAtReceptionDesk;
            }
            this.user.children.forEach(child => {
                child.isPaymentAtReceptionDesk = this.isPaymentAtReceptionDesk;
            });
            this.register();
        });
    }

    register() {
        this.isDataSubmitting = true;
        const httpOptions = {
            /*headers: new HttpHeaders({
                'Content-Type': undefined
            })*/
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(this.user));
        /*this.studentsArrayGroup.controls.forEach((formGroup: FormGroup) => {
            const vaccinationCertificate = formGroup.get('covid19VaccinationCertificate').value;
            formData.append('files', vaccinationCertificate, vaccinationCertificate.name);
        });*/
        this.studentsArrayGroup.controls.forEach((formGroup: FormGroup) => {
            const flightTicket = formGroup.get('flightTicket').value;
            formData.append('files', flightTicket, flightTicket.name);
        });
        this.httpClient.post<User>(this.SERVER_URL, formData, httpOptions).subscribe(
            (res) => {
                this.isDataSubmitting = false;
                this.isSuccessRegistration = true;

                const email = this.user.father.email ? this.user.father.email : this.user.mother.email;
                const phone = this.user.father.phone ? this.user.father.phone : this.user.mother.phone;
                const passportID = this.user.father.email ? this.user.father.passportID : this.user.mother.passportID;
                const clientName = this.user.father.email ? `${this.user.father.firstName} ${this.user.lastName}` : `${this.user.mother.firstName} ${this.user.lastName}`;
                const userId = res.id;

                const mosadId = '7001858'; // mosadId = '0'; For testings
                this.paymentData = {
                    mosad: mosadId,
                    NormalDefault: 1,
                    avour: 'וועד התמימים',
                    avourlock: userId,
                    Analytic: userId,
                    logo: '9000802_0.jpg',
                    ClientName: clientName,
                    Comments: userId,
                    Comment: userId,
                    Email: email,
                    Phone: phone,
                    Street: `${this.user.shipment.address} ${this.user.shipment.apartment}`,
                    City: this.user.shipment.city,
                    Zeout: passportID,
                    AmountLock: 1,
                    CallBack: 'https://vaadhatmimim.com/api/1.0/user/payment/',
                    pricing: this.pricing,
                    children: this.user.children,
                    currency: 1 // Shekels
                };
                if (!this.isPaymentAtReceptionDesk) {
                    const price = (this.pricing.price ? this.pricing.price : 0) * this.user.children.length;
                    this.paymentData.Amount = price;
                } else {
                    this.paymentData.Amount = 3;
                }

                if (!this.isPaymentAtReceptionDesk) {
                    this.paymentUrl = `https://www.matara.pro/nedarimplus/online/?mosad=${mosadId}&` +
                        `NormalDefault=1&` +
                        `avour=וועד%20התמימים%20העולמי%20בשיתוף%20חיוכו%20של%20ילד%20&` +
                        `avourlock=${userId}&` +
                        `Analytic=${userId}&` +
                        `logo=9000802_0.jpg&` +
                        `ClientName=${clientName}&` +
                        `Comments=${userId}&` +
                        `Comment=${userId}&` +
                        `Email=${email}&` +
                        `Phone=${phone}&` +
                        `Street=${this.user.shipment.address} ${this.user.shipment.apartment}&` +
                        `City=${this.user.shipment.city}&` +
                        `Zeout=${passportID}&` +
                        `AmountLock=1&` +
                        `Amount=${(this.pricing.price ? this.pricing.price : 0) * this.user.children.length}&` +
                        `CallBack=https://vaadhatmimim.com/api/1.0/user/payment/`;
                } else {
                    this.paymentUrl = `https://www.matara.pro/nedarimplus/online/?mosad=${mosadId}&` +
                        `NormalDefault=1&` +
                        `avour=וועד%20התמימים%20העולמי%20בשיתוף%20חיוכו%20של%20ילד%20&` +
                        `avourlock=${userId}&` +
                        `Analytic=${userId}&` +
                        `logo=9000802_0.jpg&` +
                        `ClientName=${clientName}&` +
                        `Comments=${userId}&` +
                        `Comment=${userId}&` +
                        `Email=${email}&` +
                        `Phone=${phone}&` +
                        `Street=${this.user.shipment.address} ${this.user.shipment.apartment}&` +
                        `City=${this.user.shipment.city}&` +
                        `Zeout=${passportID}&` +
                        `AmountLock=1&` +
                        `Amount=3&` +
                        `CallBack=https://vaadhatmimim.com/api/1.0/user/payment/`;
                }
                this.currentStep = 4;
                this.onPayClick();
            },
            (err) => {
                this.isDataSubmitting = false;
                this.generalError = true;
                console.log(err);
            }
        );
    }

    get submitButtonText() {
        if (this.currentStep !== 3) {
            return this.currentStep + 1;
        } else {
            return 'התשלום';
        }
    }

    get familyName() {
        return this.registrationForm.get('familyName');
    }

    get fatherName() {
        return this.registrationForm.get('fatherName');
    }

    get motherName() {
        return this.registrationForm.get('motherName');
    }

    get fatherID() {
        return this.registrationForm.get('fatherID');
    }

    get motherID() {
        return this.registrationForm.get('motherID');
    }

    get fatherBirthDate() {
        return this.registrationForm.get('fatherBirthDate');
    }

    get motherBirthDate() {
        return this.registrationForm.get('motherBirthDate');
    }

    get fatherEmail() {
        return this.parentEmailsGroup.get('fatherEmail');
    }

    get motherEmail() {
        return this.parentEmailsGroup.get('motherEmail');
    }

    get motherPhone() {
        return this.parentPhonesGroup.get('motherPhone');
    }

    get fatherPhone() {
        return this.parentPhonesGroup.get('fatherPhone');
    }

    get city() {
        return this.registrationForm.get('city');
    }

    get apartment() {
        return this.registrationForm.get('apartment');
    }

    get zipCode() {
        return this.registrationForm.get('zipCode');
    }

    get phone() {
        return this.registrationForm.get('phone');
    }

    get address() {
        return this.registrationForm.get('address');
    }

    get whatsAppPhone() {
        return this.registrationForm.get('whatsAppPhone');
    }

    get randomId() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}
