/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagenotfound.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pagenotfound.component";
var styles_PagenotfoundComponent = [i0.styles];
var RenderType_PagenotfoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PagenotfoundComponent, data: {} });
export { RenderType_PagenotfoundComponent as RenderType_PagenotfoundComponent };
export function View_PagenotfoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "text-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title"], ["data-content", "404"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 404 "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops, the page you're looking for doesn't exist. "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "btn btn-default"], ["href", "/"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to homepage"]))], null, null); }
export function View_PagenotfoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagenotfound", [], null, null, null, View_PagenotfoundComponent_0, RenderType_PagenotfoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.PagenotfoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PagenotfoundComponentNgFactory = i1.ɵccf("app-pagenotfound", i2.PagenotfoundComponent, View_PagenotfoundComponent_Host_0, {}, {}, []);
export { PagenotfoundComponentNgFactory as PagenotfoundComponentNgFactory };
