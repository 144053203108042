import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {WOW} from 'wowjs/dist/wow.min';
import {forkJoin} from 'rxjs';
import {PricingPlanService} from '../../services/pricing-plan.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {
    pricingMapping = [];
    isRegistrationEnabled = true; // TODO: Enable registration

    constructor(
        public router: Router,
        private _scrollToService: ScrollToService,
        private pricingPlanService: PricingPlanService
    ) {
    }

    ngOnInit() {
        this.pricingMapping = [];

        forkJoin([this.pricingPlanService.httpFetchPricingPlans()]).subscribe(results => {
            this.pricingMapping = results[0];
            this.isRegistrationEnabled = this.pricingMapping.length > 0;
        });
    }

    ngAfterViewInit() {
        const wow = new WOW({
            live: false
        });
        wow.init();
    }

    openRegistration(pricing) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                pricing: btoa(unescape(encodeURIComponent(JSON.stringify(pricing))))
            }
        };
        this.router.navigate(['/registration'], navigationExtras);
        const config: ScrollToConfigOptions = {
            target: 'header',
            duration: 650,
            easing: 'easeOutElastic'
        };

        this._scrollToService.scrollTo(config);
    }
}
