<div class="registration-terms-content-dialog">
    <mat-spinner [hidden]="!isDataLoading"></mat-spinner>
    <div [ngClass]="{'semi-transparent': !!isDataLoading}">
        <h2 mat-dialog-title [innerHtml]="siteConfiguration.terms_and_conditions_title | safeHtml"></h2>
        <mat-dialog-content class="mat-typography"
                            [innerHtml]="siteConfiguration.terms_and_conditions_content | safeHtml">
        </mat-dialog-content>

        <div class="row" dir="rtl">
            <div class="col-md-12">
                <mat-checkbox color="primary" class="float-right"
                              [(ngModel)]="isTermsAccepted" [id]="'isTermsAcceptedCheckbox'">
                    אני מקבל תנאים
                </mat-checkbox>
            </div>
        </div>
        <div class="row" dir="rtl" [hidden]="!isShowPaymentOptions">
            <div class="col-md-12">
                <mat-radio-group
                    aria-labelledby="payment-radio-group-label"
                    class="payment-radio-group"
                    [(ngModel)]="isPaymentAtReceptionDesk">
                    <mat-radio-button [value]="false" class="payment-radio-button" [checked]="true">
                        תשלום באמצעות כרטיס אשראי
                    </mat-radio-button>
                    <mat-radio-button [value]="true" class="payment-radio-button" [checked]="false">
                        תשלום אצל הנציג בישיבה
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <mat-dialog-actions align="end" dir="rtl">
            <button type="button" class="float-left" (click)="onRegisterClicked()"
                    [disabled]="!isTermsAccepted">
                <div class="btn-data">
                    <div class="text">המשך</div>
                </div>
            </button>
        </mat-dialog-actions>
    </div>
</div>
