import {Injectable} from '@angular/core';
import {HandleError, HttpErrorHandlerService} from './http-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {PricingPlan} from '../interfaces/pricing';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


@Injectable()
export class PricingPlanService {

    private handleError: HandleError;
    pricingPlanUrl = 'api/1.0/user/payment/plan/';

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandlerService) {
        this.handleError = httpErrorHandler.createHandleError('PricingPlanService');
    }

    getPricingPlans(): Observable<PricingPlan[]> {
        return this.httpFetchPricingPlans().pipe(
            catchError(this.handleError('getPricingPlans', []))
        );
    }

    httpFetchPricingPlans(): Observable<PricingPlan[]> {
        return this.http.get<PricingPlan[]>(this.pricingPlanUrl, httpOptions);
    }
}
