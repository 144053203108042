import {Component, Input, OnInit} from '@angular/core';
import {SiteConfigurationService} from '../../services/configuration.service';
import {forkJoin} from 'rxjs';
import {SiteConfiguration} from '../../interfaces/configuration';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-registration-terms-content-dialog',
    templateUrl: 'registration-terms-content-dialog.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationTermsContentDialogComponent implements OnInit {
    siteConfiguration: SiteConfiguration;
    isDataLoading: boolean;
    isPaymentAtReceptionDesk: boolean;
    isTermsAccepted: boolean;
    isShowPaymentOptions: boolean;

    constructor(
        public dialogRef: MatDialogRef<RegistrationTermsContentDialogComponent>,
        private siteConfigurationService: SiteConfigurationService
    ) {
    }

    ngOnInit() {
        this.siteConfiguration = {};
        this.isDataLoading = true;
        this.dialogRef.disableClose = true;
        this.isTermsAccepted = false;
        this.isShowPaymentOptions = false;
        this.isPaymentAtReceptionDesk = false;
        forkJoin([this.siteConfigurationService.httpSiteConfiguration()]).subscribe(results => {
            this.siteConfiguration = results[0];
            this.isDataLoading = false;
        });
    }

    onRegisterClicked() {
        this.dialogRef.close({isPaymentAtReceptionDesk: this.isPaymentAtReceptionDesk});
    }
}
