import {Injectable} from '@angular/core';
import {HandleError, HttpErrorHandlerService} from './http-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SiteConfiguration} from '../interfaces/configuration';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


@Injectable()
export class SiteConfigurationService {

    private handleError: HandleError;
    siteConfigurationUrl = 'api/1.0/configuration/';

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandlerService) {
        this.handleError = httpErrorHandler.createHandleError('SiteConfigurationService');
    }

    getSiteConfiguration(): Observable<SiteConfiguration> {
        return this.httpSiteConfiguration().pipe(
            catchError(this.handleError('getSiteConfiguration', {} as SiteConfiguration))
        );
    }

    httpSiteConfiguration(): Observable<SiteConfiguration> {
        return this.http.get<SiteConfiguration>(this.siteConfigurationUrl, httpOptions).pipe(
            map((data) => {
                if (data.start_tishrei_date) {
                    data.start_tishrei_date = new Date(data.start_tishrei_date);
                } else {
                    data.start_tishrei_date = new Date();
                }
                if (data.end_tishrei_date) {
                    data.end_tishrei_date = new Date(data.end_tishrei_date);
                } else {
                    const oneYearFromNow = new Date();
                    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                    data.end_tishrei_date = oneYearFromNow;
                }
                return data;
            })
        );
    }
}
