<div class="registration-page">
    <div class="main-image-wrapper"></div>

    <div class="content-form">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 wow slideInRight fadeIn" data-wow-duration="1.6s">
                    <h4 style="color: #a26a33; font-weight: bolder;" [textContent]="pricing.content_title"></h4>
                    <p [textContent]="pricing.content"></p>
                    <div class="accordion" id="accordion">

                        <div *ngFor="let cellContent of pricing.contents; index as k;">
                            <div class="card">
                                <div class="card-header" [ngClass]="{'collapsed': k > 0}" data-toggle="collapse"
                                     [attr.href]="'#collapse_' + k" [attr.aria-expanded]="k == 0">
                                    <a class="card-link">
                                        <span [textContent]="cellContent.title"></span>
                                        <i class="material-icons md-36">arrow_back_ios</i>
                                    </a>
                                </div>
                            </div>
                            <div [id]="'collapse_' + k" class="collapse" data-parent="#accordion"
                                 [ngClass]="{'show': k == 0, 'collapsed': k > 0}">
                                <div class="card-body" [textContent]="cellContent.content"></div>
                            </div>
                            <div class="separator" *ngIf="k < pricing.contents.length - 1"></div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 wow slideInLeft fadeIn" data-wow-duration="1.6s">
                    <mat-spinner [hidden]="!isDataSubmitting"></mat-spinner>
                    <div class="steps-form" [ngClass]="{'semi-transparent': !!isDataSubmitting}">
                        <div *ngIf="isSuccessRegistration && !isPaymentAtReceptionDesk" class="alert success">
                            נרשמת בהצלחה, כעת עליך לעבור את תהליך התשלום
                        </div>

                        <div *ngIf="isSuccessRegistration && isPaymentAtReceptionDesk" class="alert success">
                            <b>שלב א של ההרשמה בוצע בהצלחה</b>
                            <br/>
                            כעת עליך לעבור לשלב הזנת פרטי האשראי לצורך פקדון (כמפורט ב׳תנאים והסכמות׳ שחתמת לעיל) כרטיס
                            האשראי יחויב בשלושה שקלים
                            <br/>
                            <b>שים לב! שלב זה הוא חלק מתהליך הרישום, ללא פקדון בנכם לא יוכל להשתתף בתוכנית הוועד!</b>
                        </div>

                        <h4 class="form-caption" *ngIf="currentStep < 4">פרטי הרישום</h4>
                        <div *ngIf="generalError"
                             class="alert error">
                            <div>
                                אירעה שגיאה. אנא נסו שוב
                            </div>
                        </div>
                        <div class="row" *ngIf="currentStep < 4">
                            <div class="col-md-12">
                                <div class="steps-wrapper">
                                    <div class="step-indicator active" (click)="onStepClick(1)"><a class="btn">1</a>
                                    </div>
                                    <div class="step-line"></div>
                                    <div class="step-indicator" [ngClass]="{'active': passedStep >= 2}"
                                         (click)="onStepClick(2)"><a class="btn">2</a></div>
                                    <div class="step-line"></div>
                                    <div class="step-indicator" [ngClass]="{'active': passedStep == 3}"
                                         (click)="onStepClick(3)"><a class="btn">3</a></div>
                                </div>
                            </div>
                        </div>

                        <form [formGroup]="registrationForm" (ngSubmit)="onRegisterSubmit()" novalidate
                              *ngIf="currentStep < 4">
                            <div class="first-step" *ngIf="currentStep == 1">
                                <div class="step-caption">פרטי ההורים</div>
                                <div class="form-group">
                                    <label for="familyName"></label>
                                    <input dir="rtl" type="text" class="form-control" formControlName="familyName"
                                           id="familyName"
                                           placeholder="שם המשפחה"
                                           required
                                           [ngClass]="{'error': isFieldInvalid(familyName)}">

                                    <div *ngIf="isFieldInvalid(familyName)"
                                         class="alert error">
                                        <div *ngIf="familyName.errors.required">
                                            אנא הכנס את שם המשפחה
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="fatherName"></label>
                                    <input dir="rtl" type="text" class="form-control" formControlName="fatherName"
                                           id="fatherName" placeholder="שם האב"
                                           required
                                           [ngClass]="{'error': isFieldInvalid(fatherName)}">

                                    <div *ngIf="isFieldInvalid(fatherName)"
                                         class="alert error">
                                        <div *ngIf="fatherName.errors.required">
                                            אנא הזן את שם האב
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="fatherBirthdate"></label>
                                        <input [matDatepicker]="fatherBirthdate" dir="rtl" placeholder="תאריך לידה"
                                               class="form-control" id="fatherBirthdate"
                                               formControlName="fatherBirthDate"
                                               [ngClass]="{'error': isFieldInvalid(fatherBirthDate)}"
                                               required>
                                        <mat-datepicker-toggle [for]="fatherBirthdate"></mat-datepicker-toggle>
                                        <mat-datepicker #fatherBirthdate></mat-datepicker>

                                        <div
                                            *ngIf="isFieldInvalid(fatherBirthDate)"
                                            class="alert error">
                                            <div *ngIf="fatherBirthDate.errors.required">
                                                אנא הכנס את תאריך הלידה של האב
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="fatherID"></label>
                                        <input dir="rtl" type="text" class="form-control" id="fatherID"
                                               placeholder="תעודת זהות" formControlName="fatherID"
                                               formControlName="fatherID"
                                               required
                                               [ngClass]="{'error': isFieldInvalid(fatherID)}">

                                        <div *ngIf="isFieldInvalid(fatherID)"
                                             class="alert error">
                                            <div *ngIf="fatherID.errors.required">
                                                אנא הזן את תעודת הזהות של האב
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="motherName"></label>
                                    <input dir="rtl" type="text" class="form-control" id="motherName"
                                           placeholder="שם האם"
                                           [ngClass]="{'error': isFieldInvalid(motherName)}"
                                           required
                                           formControlName="motherName">

                                    <div *ngIf="isFieldInvalid(motherName)"
                                         class="alert error">
                                        <div *ngIf="motherName.errors.required">
                                            אנא הזן את שם האם
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="motherBirthdate"></label>
                                        <input [matDatepicker]="motherBirthdate" dir="rtl" placeholder="תאריך לידה"
                                               class="form-control" id="motherBirthdate"
                                               formControlName="motherBirthDate"
                                               [ngClass]="{'error': isFieldInvalid(motherBirthDate)}"
                                               required>
                                        <mat-datepicker-toggle [for]="motherBirthdate"></mat-datepicker-toggle>
                                        <mat-datepicker #motherBirthdate></mat-datepicker>

                                        <div
                                            *ngIf="isFieldInvalid(motherBirthDate)"
                                            class="alert error">
                                            <div *ngIf="motherBirthDate.errors.required">
                                                אנא הכנס את תאריך הלידה של האם
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="motherID"></label>
                                        <input dir="rtl" type="text" class="form-control" id="motherID"
                                               placeholder="תעודת זהות" formControlName="motherID"
                                               formControlName="motherID"
                                               [ngClass]="{'error': isFieldInvalid(motherID)}"
                                               required>

                                        <div *ngIf="isFieldInvalid(motherID)"
                                             class="alert error">
                                            <div *ngIf="motherID.errors.required">
                                                אנא הזן את תעודת הזהות של האם
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="second-step" *ngIf="currentStep == 2">
                                <div class="step-caption">פרטי קשר</div>
                                <div class="form-row">
                                    <div class="form-group col-md-6" formGroupName="parentEmailsGroup">
                                        <label for="fatherEmailAddress"></label>
                                        <input dir="rtl" type="email" class="form-control" id="fatherEmailAddress"
                                               placeholder="מייל של האב" formControlName="fatherEmail"
                                               [ngClass]="{'error': !!fatherEmail.errors?.parentEmails || !!fatherEmail.errors?.fatherEmail}">

                                        <div *ngIf="fatherEmail.errors?.fatherEmail"
                                             class="alert error">
                                            <div>
                                                כתובת הדוא"ל לא חוקית
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6" formGroupName="parentPhonesGroup">
                                        <label for="fatherPhone"></label>
                                        <input dir="rtl" type="number" class="form-control" id="fatherPhone"
                                               placeholder="טלפון של האב" formControlName="fatherPhone"
                                               [ngClass]="{'error': !!fatherPhone.errors?.parentPhones}">
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6" formGroupName="parentEmailsGroup">
                                        <label for="motherEmailAddress"></label>
                                        <input dir="rtl" type="email" class="form-control" id="motherEmailAddress"
                                               placeholder="מייל של האם" formControlName="motherEmail"
                                               [ngClass]="{'error': !!motherEmail.errors?.parentEmails || !!motherEmail.errors?.motherEmail}">

                                        <div *ngIf="motherEmail.errors?.parentEmails"
                                             class="alert error">
                                            <div>
                                                הזן את המייל של האב או של האם
                                            </div>
                                        </div>

                                        <div *ngIf="motherEmail.errors?.motherEmail"
                                             class="alert error">
                                            <div>
                                                כתובת דוא"ל לא חוקית
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6" formGroupName="parentPhonesGroup">
                                        <label for="motherPhone"></label>
                                        <input dir="rtl" type="number" class="form-control" id="motherPhone"
                                               placeholder="טלפון של האם" formControlName="motherPhone"
                                               [ngClass]="{'error': !!motherPhone.errors?.parentPhones}">

                                        <div *ngIf="motherPhone.errors?.parentPhones"
                                             class="alert error">
                                            <div>
                                                הזן את מספר הטלפון של האב או של האם
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="step-caption">כתובת למשלוח דואר</div>

                                <div class="form-group">
                                    <label for="whatsAppPhone"></label>
                                    <input dir="rtl" type="text" class="form-control" id="whatsAppPhone"
                                           placeholder="טלפון וואטסאפ" formControlName="whatsAppPhone">
                                </div>

                                <div class="form-group">
                                    <label for="street"></label>
                                    <input dir="rtl" type="text" class="form-control" id="street"
                                           placeholder="כתובת" formControlName="address" required
                                           [ngClass]="{'error': isFieldInvalid(address)}">

                                    <div
                                        *ngIf="isFieldInvalid(address)"
                                        class="alert error">
                                        <div *ngIf="address.errors.required">
                                            אנא הכנס את הכתובת
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="apartment"></label>
                                        <input dir="rtl" type="text" class="form-control" id="apartment"
                                               placeholder="מספר הדירה" formControlName="apartment" required
                                               [ngClass]="{'error': isFieldInvalid(apartment)}">

                                        <div
                                            *ngIf="isFieldInvalid(apartment)"
                                            class="alert error">
                                            <div *ngIf="apartment.errors.required">
                                                אנא הכנס את מספר הדירה
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="zipcode"></label>
                                        <input dir="rtl" type="number" class="form-control" id="zipcode"
                                               placeholder="מיקוד" formControlName="zipCode" required
                                               [ngClass]="{'error': isFieldInvalid(zipCode)}">
                                        <div
                                            *ngIf="isFieldInvalid(zipCode)"
                                            class="alert error">
                                            <div *ngIf="zipCode.errors.required">
                                                אנא הכנס את המיקוד
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="city"></label>
                                        <input dir="rtl" type="text" class="form-control" id="city" placeholder="עיר"
                                               formControlName="city" required
                                               [ngClass]="{'error': isFieldInvalid(city)}">
                                        <div
                                            *ngIf="isFieldInvalid(city)"
                                            class="alert error">
                                            <div *ngIf="city.errors.required">
                                                אנא הכנס את העיר
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label for="phone"></label>
                                        <input dir="rtl" type="number" class="form-control" id="phone"
                                               placeholder="טלפון"
                                               formControlName="phone" required
                                               [ngClass]="{'error': isFieldInvalid(phone)}">
                                        <div
                                            *ngIf="isFieldInvalid(phone)"
                                            class="alert error">
                                            <div *ngIf="phone.errors.required">
                                                אנא הכנס את הטלפון
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="third-step" *ngIf="currentStep == 3">
                                <div class="step-caption">פרטי התלמיד</div>

                                <div class="step-attention-text">
                                    לתשומת ליבכם! מקום הלינה משובץ על-פי הפרטים שמילאתם. אנא הקפידו ודייקו, על מנת שישובץ עם חבריו.
                                </div>
                                <div
                                    *ngFor="let studentFormGroup of studentsArrayGroup.controls;  index as k; let i = index;
                                    let arrivalDate = randomId; let leaveDate = randomId;">
                                    <div [formGroup]="studentFormGroup">

                                        <div class="form-row" *ngIf="k != 0">
                                            <button type="button" class="float-right delete-btn"
                                                    (click)="removeStudent(k)">
                                                <div class="icon-wrapper">
                                                    <i class="material-icons md-36">delete</i>
                                                </div>
                                            </button>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label [for]="'firstName' + i"></label>
                                                <input dir="rtl" type="text" class="form-control"
                                                       [id]="'firstName' + i"
                                                       placeholder="שם"
                                                       formControlName="firstName"
                                                       required
                                                       [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('firstName'))}">
                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('firstName'))"
                                                    class="alert error">
                                                    <div *ngIf="studentFormGroup.get('firstName').errors.required">
                                                        אנא הכנס את השם הפרטי
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label [for]="'lastName' + i"></label>
                                                <input dir="rtl" type="text" class="form-control" [id]="'lastName' + i"
                                                       placeholder="שם המשפחה" formControlName="lastName"
                                                       required
                                                       [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('lastName'))}">
                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('lastName'))"
                                                    class="alert error">
                                                    <div *ngIf="studentFormGroup.get('lastName').errors.required">
                                                        אנא הכנס את שם המשפחה
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label [for]="i"></label>
                                                <input [matDatepicker]="i" dir="rtl"
                                                       placeholder="תאריך לידה" dir="rtl"
                                                       class="form-control" [id]="i" formControlName="birthDate"
                                                       required
                                                       [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('birthDate'))}">
                                                <mat-datepicker-toggle [for]="i"></mat-datepicker-toggle>
                                                <mat-datepicker #i></mat-datepicker>

                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('birthDate'))"
                                                    class="alert error">
                                                    <div *ngIf="studentFormGroup.get('birthDate').errors.required">
                                                        אנא הכנס את תאריך הלידה
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label [for]="'ID' + i"></label>
                                                <input dir="rtl" type="text" class="form-control" [id]="'ID' + i"
                                                       placeholder="תעודת זהות" formControlName="passportID"
                                                       required
                                                       [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('passportID'))}">

                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('passportID'))"
                                                    class="alert error">
                                                    <div *ngIf="studentFormGroup.get('passportID').errors.required">
                                                        אנא הכנס את תעודת הזהות
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label [for]="'yeshiva' + i">ישיבה</label>
                                                <select class="form-control" [id]="'yeshiva' + i" required dir="rtl"
                                                        formControlName="yeshiva">
                                                    <option value="" disabled selected hidden>בחר ישיבה</option>
                                                    <option *ngFor="let yeshiva of yeshivas" [value]="yeshiva.id"
                                                            [textContent]="yeshiva.name"></option>
                                                </select>

                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('yeshiva'))"
                                                    class="alert error">
                                                    <div
                                                        *ngIf="studentFormGroup.get('yeshiva').errors.required">
                                                        אנא בחר ישיבה
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label [for]="'lesson' + i">שיעור</label>
                                                <select class="form-control" [id]="'lesson' + i" required dir="rtl"
                                                        formControlName="lesson">
                                                    <option value="" disabled selected hidden>בחר שיעור</option>
                                                    <option *ngFor="let lesson of lessons" [value]="lesson.id"
                                                            [textContent]="lesson.name"></option>
                                                </select>

                                                <div
                                                    *ngIf="isFieldInvalid(studentFormGroup.get('lesson'))"
                                                    class="alert error">
                                                    <div
                                                        *ngIf="studentFormGroup.get('lesson').errors.required">
                                                        אנא בחר שיעור
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="pricing.is_show_amenities">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label [for]="arrivalDate"></label>
                                                    <input [matDatepicker]="arrivalDate" dir="rtl"
                                                           placeholder="תאריך הגעה" dir="rtl"
                                                           class="form-control" [id]="arrivalDate"
                                                           formControlName="arrivalDate"
                                                           required
                                                           [min]="siteConfiguration.start_tishrei_date"
                                                           [max]="siteConfiguration.end_tishrei_date"
                                                           [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('arrivalDate'))}">
                                                    <mat-datepicker-toggle [for]="arrivalDate"></mat-datepicker-toggle>
                                                    <mat-datepicker #arrivalDate></mat-datepicker>

                                                    <div
                                                        *ngIf="isFieldInvalid(studentFormGroup.get('arrivalDate'))"
                                                        class="alert error">
                                                        <div
                                                            *ngIf="studentFormGroup.get('arrivalDate').errors.required">
                                                            אנא הכנס את תאריך ההגעה
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label [for]="leaveDate"></label>
                                                    <input [matDatepicker]="leaveDate" dir="rtl"
                                                           placeholder="תאריך העזיבה" dir="rtl"
                                                           class="form-control" [id]="leaveDate"
                                                           formControlName="leaveDate"
                                                           [min]="siteConfiguration.start_tishrei_date"
                                                           [max]="siteConfiguration.end_tishrei_date"
                                                           required
                                                           [ngClass]="{'error': isFieldInvalid(studentFormGroup.get('leaveDate'))}">
                                                    <mat-datepicker-toggle [for]="leaveDate"></mat-datepicker-toggle>
                                                    <mat-datepicker #leaveDate></mat-datepicker>

                                                    <div
                                                        *ngIf="isFieldInvalid(studentFormGroup.get('leaveDate'))"
                                                        class="alert error">
                                                        <div *ngIf="studentFormGroup.get('leaveDate').errors.required">
                                                            אנא הכנס את תאריך העזיבה
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label [for]="'medicalRemarks' + k"></label>
                                                <textarea dir="rtl" class="form-control" [id]="'medicalRemarks' + k"
                                                          placeholder="הערות רפואיות"
                                                          formControlName="medicalRemarks"></textarea>
                                            </div>

                                            <!--<div class="form-group">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>תו ירוק</mat-label>
                                                    <ngx-mat-file-input formControlName="covid19VaccinationCertificate"
                                                                        placeholder="תו ירוק"
                                                                        [id]="'covid19VaccinationCertificate' + k"
                                                                        required></ngx-mat-file-input>
                                                    <div
                                                        *ngIf="isFieldInvalid(studentFormGroup.get('covid19VaccinationCertificate'))"
                                                        class="alert error">
                                                        <div
                                                            *ngIf="studentFormGroup.get('covid19VaccinationCertificate').errors.required">
                                                            אנא העלה את תו הירוק
                                                        </div>
                                                    </div>
                                                </mat-form-field>
                                            </div>-->

                                            <div class="form-group">
                                                <mat-form-field appearance="outline">
                                                    <mat-label>כרטיס הטיסה</mat-label>
                                                    <ngx-mat-file-input formControlName="flightTicket"
                                                                        placeholder="כרטיס הטיסה"
                                                                        [id]="'flightTicket' + k"
                                                                        required></ngx-mat-file-input>
                                                    <div
                                                        *ngIf="isFieldInvalid(studentFormGroup.get('flightTicket'))"
                                                        class="alert error">
                                                        <div
                                                            *ngIf="studentFormGroup.get('flightTicket').errors.required">
                                                            אנא העלה את כרטיס הטיסה
                                                        </div>
                                                    </div>
                                                </mat-form-field>
                                            </div>

                                            <div class="form-group">
                                                <mat-checkbox color="primary"
                                                              formControlName="isLodging" [id]="'isLodging' + k">מעוניין
                                                    לקבל מקום
                                                    לינה
                                                </mat-checkbox>
                                            </div>

                                            <div class="form-group">
                                                <mat-checkbox color="primary"
                                                              formControlName="isFood" [id]="'isFood' + k">מעוניין
                                                    להיכנס לארוחות
                                                </mat-checkbox>
                                            </div>
                                            <!--<div class="form-group">
                                                <mat-checkbox color="primary"
                                                              formControlName="hasMedicalInsurance"
                                                              [id]="'hasMedicalInsurance' + k">ביטוח רפואי
                                                </mat-checkbox>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="button" class="step-btn float-right" (click)="addStudent()"
                                    *ngIf="currentStep == 3">
                                <div class="btn-data">
                                    <i class="material-icons md-36">add</i>
                                    <div class="text">בן נוסף</div>
                                </div>
                            </button>

                            <button class="step-btn float-left" type="submit" *ngIf="currentStep < 4">
                                <div class="btn-data">
                                    <div class="text" *ngIf="currentStep < 3">לשלב <span class="btn-step-counter"
                                                                                         [textContent]="submitButtonText"></span>
                                    </div>
                                    <div class="text" *ngIf="currentStep === 3">המשך</div>
                                    <i class="material-icons md-36">arrow_back_ios</i>
                                </div>
                            </button>
                        </form>
                        <!--<div class="row" *ngIf="currentStep == 4">
                            <button type="button" class="step-btn float-left" (click)="onPayClick()">
                                <div class="btn-data" *ngIf="isPaymentAtReceptionDesk">
                                    <div class="text">העבר לתשלום הפקדון</div>
                                </div>
                                <div class="btn-data" *ngIf="!isPaymentAtReceptionDesk">
                                    <div class="text">העבר לתשלום</div>
                                </div>
                            </button>
                        </div>-->
                        <!--<div class="row payment-form-row" *ngIf="currentStep == 4">
                            <iframe class="payment-frame" width="100%" height="100%" [src]="paymentUrl | safe"></iframe>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-scroll-to-top></app-scroll-to-top>
