<div class="footer" id="contact-us" #contactUs>
    <div class="layout">
        <div class="col-sm-4 wow slideInUp fadeIn" data-wow-duration="2s">

            <mat-spinner [hidden]="!isDataSubmitting"></mat-spinner>

            <div *ngIf="isSuccessContactUs" class="alert success">
                ההודעה נשלחה בהצלחה
            </div>
            <div *ngIf="generalError" class="alert error">
                <div>
                    אירעה שגיאה. אנא נסו שוב
                </div>
            </div>

            <form novalidate (ngSubmit)="onSendMessageSubmit()" [formGroup]="contactUsFormGroup"
                  [ngClass]="{'semi-transparent': !!isDataSubmitting}">
                <p class="form-text">צור איתנו קשר</p>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="firstName"></label>
                        <input type="text" class="form-control" id="firstName" placeholder="*שם פרטי"
                               formControlName="firstName" required
                               [ngClass]="{'error': isFieldInvalid(firstName)}">

                        <div *ngIf="isFieldInvalid(firstName)"
                             class="alert error">
                            <div *ngIf="firstName.errors.required">
                                אנא הכנס את שם הפרטי
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="lastName"></label>
                        <input type="text" class="form-control" id="lastName" placeholder="*שם משפחה"
                               formControlName="lastName" [ngClass]="{'error': isFieldInvalid(lastName)}" required>

                        <div *ngIf="isFieldInvalid(lastName)"
                             class="alert error">
                            <div *ngIf="lastName.errors.required">
                                אנא הכנס את שם המשפחה
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email"></label>
                    <input type="text" class="form-control" id="email" placeholder="*אימייל" required
                           formControlName="email" [ngClass]="{'error': isFieldInvalid(email)}">

                    <div *ngIf="isFieldInvalid(email)"
                         class="alert error">
                        <div *ngIf="email.errors.required">
                            אנא הכנס את הדוא"ל
                        </div>
                        <div *ngIf="email.errors.email">
                            כתובת הדוא"ל לא חוקית
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    אנחנו זמינים גם בוואצפ <a href="https://wa.me/+13478855896?text=" target="_blank">+13478855896</a>
                </div>
                <div class="form-group">
                    <label for="message"></label>
                    <textarea class="form-control" id="message" placeholder="הודעה" formControlName="message"
                              required [ngClass]="{'error': isFieldInvalid(message)}"></textarea>

                    <div *ngIf="isFieldInvalid(message)"
                         class="alert error">
                        <div *ngIf="message.errors.required">
                            אנא הכנס את שם ההודעה
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn-send">שלח</button>
            </form>
        </div>
        <div class="col-sm-4 wow slideInUp fadeIn" data-wow-duration="2s">
            <div class="footer-map" id="map"></div>
        </div>

        <div class="col-sm-4 wow slideInUp fadeIn" data-wow-duration="2s">
            <img src="../../../static/app/assets/img/logo-sm.png" style="margin-bottom: 30px;">
            <p>ועד תלמידי התמימים העולמי</p>
            <p>Vaad Talmidei Hatmimim</p>
            <p dir="ltr">784 Eastern Parkway Suite 400</p>
            <p>Brooklyn, NY 11213</p>
            <p>347-770-4275</p>
        </div>
    </div>
</div>
