<div class="header" id="header">
    <div class="personalAccount-header">
        <button class="button-account">אזור אישי</button>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="logo-container">
        <a class="navbar-brand" href="#">
            <img class="logo" src="../../../static/app/assets/img/logo.png"/>
        </a>
    </div>

    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-start" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link" href="#">דף הבית<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngx-scroll-to]="'#activity'">מה בפעילות</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngx-scroll-to]="'#registration'">הרשמה</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngx-scroll-to]="'#gallery'">גלריות</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngx-scroll-to]="'#about'">אודות</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngx-scroll-to]="'#contact-us'">צור קשר</a>
            </li>
        </ul>
    </div>

    <button class="button-donations">לתרומות</button>
</nav>
