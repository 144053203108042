import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {LandingComponent} from './components/landing/landing.component';
import {PagenotfoundComponent} from './components/pagenotfound/pagenotfound.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MAT_DATE_LOCALE} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LessonService} from './services/lesson.service';
import {YeshivaService} from './services/yeshiva.service';
import {HttpErrorHandlerService} from './services/http-error-handler.service';
import {MessageService} from './services/message.service';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ScrollToTopComponent} from './components/scroll-to-top/scroll-to-top.component';
import {MAT_CHECKBOX_CLICK_ACTION, MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SafePipe} from './classes/safe-pipe';
import {VarDirective} from './directives/var.directive';
import {PricingPlanService} from './services/pricing-plan.service';
import {MatIconModule} from '@angular/material/icon';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {SiteConfigurationService} from './services/configuration.service';
import {RegistrationTermsContentDialogComponent} from './components/registration/registration-terms.component';
import {MatDialogConfig, MatDialogModule} from '@angular/material/dialog';
import {SafeHtmlPipe} from './classes/safe-pipe-html';
import {MatRadioModule} from '@angular/material/radio';
import {PaymentComponent} from './components/payment/payment.component';
import {PaymentSuccessComponent} from './components/payment/payment-success.component';
import {PaymentErrorComponent} from './components/payment/payment-error.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LandingComponent,
        PagenotfoundComponent,
        RegistrationComponent,
        PaymentComponent,
        PaymentSuccessComponent,
        PaymentErrorComponent,
        ScrollToTopComponent,
        RegistrationTermsContentDialogComponent,
        SafePipe,
        SafeHtmlPipe,
        VarDirective
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxMatFileInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollToModule.forRoot()
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'iw-IW'},
        {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'},
        MatDatepickerModule,
        MatCheckboxModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        LessonService,
        YeshivaService,
        MessageService,
        PricingPlanService,
        SiteConfigurationService,
        HttpErrorHandlerService,
        MatDialogConfig
    ],
    bootstrap: [AppComponent],
    entryComponents: [RegistrationTermsContentDialogComponent]
})
export class AppModule {
    constructor() {
    }
}
